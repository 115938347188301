var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('div',{staticClass:"field",class:_vm.isDrawer && 'dropdown'},[_c('input',{staticClass:"input",class:{
      'drawer-active': _vm.isDrawer 
      && _vm.placeholder !== 'Tipo do acessório' 
      && _vm.placeholder !== 'UF' 
      && _vm.placeholder !== 'Marca'
      && _vm.placeholder !== 'Ano'
      && _vm.placeholder !== 'Modalidade',
      '': !_vm.isDrawer,
      focused: _vm.isFocused,
      onError: _vm.errors && _vm.errors.length,
    },attrs:{"readonly":_vm.isDisabled,"type":_vm.type},domProps:{"value":_vm.model},on:{"focus":function($event){_vm.isFocused = true;},"blur":function($event){_vm.isFocused = false},"input":function($event){_vm.$emit('input', $event.target.value.toUpperCase())}}}),_c('label',{class:{
      'filled-outside': _vm.isDrawer 
      && _vm.placeholder 
      !== 'Tipo do acessório' 
      && _vm.placeholder !== 'UF' 
      && _vm.placeholder !== 'Marca'
      && _vm.placeholder !== 'Ano'
      && _vm.placeholder !== 'Modalidade',
      'filled': _vm.model
    }},[_vm._v(_vm._s(_vm.placeholder))]),(_vm.errors && _vm.errors.length && !_vm.isDrawer)?_c('span',{staticStyle:{"font-size":"12px"},style:({ color: _vm.isDisabled ? '#5db247' : 'red' })},[_vm._v(_vm._s(_vm.errors[0]))]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }